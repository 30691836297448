import {InterestType} from 'components/service/loan-type.types';
import {productAvailability} from 'constants/productDefinition';
import _ from 'lodash';
import nxModule from 'nxModule';
import {setBreadcrumbs} from 'shared/utils/set-breadcrumbs';

import templateUrl from './loan-product-details.template.html';

nxModule.component('loanProductDetails', {
  templateUrl,
  controller: function (loanProductsCache, depositAccountTypeService, $route, $location, notification, confirmation,
                        breadcrumbs, ledgerTagCache, feeDefinitionsCache, command, branchService,
                        productDefinitionService, permissionService) {
    const that = this;

    that.$onInit = async () => {
      // clear all cache related data to make sure user see latest available data
      // for edit
      that.clearCache();

      that.branches = await branchService.toPromise();

      const productId = Number($route.current.params['productId']);
      that.createMode = !productId;
      if (!that.createMode) {
        const loanTypes = await loanProductsCache.toPromise();
        that.loanProduct = _.find(loanTypes, (d) => d.id === productId);
        setBreadcrumbs(breadcrumbs, 'loan-product-label', this.loanProduct.productDefinition.productName);

        if (that.loanProduct.loanHolidays) {
          that.loanProduct.holidaysCount = that.loanProduct.loanHolidays.length;
        }

        if (!that.loanProduct.productDefinition.customerTypeConstraints) {
          that.loanProduct.productDefinition.customerTypeConstraints = [];
        }

        if (that.loanProduct.letters.length > 1) {
          that.loanProduct.letters = that.loanProduct.letters.sort((a, b) => a.orderNo - b.orderNo);
        }

        that.loanProduct.recalculatePenalty = !_.isEmpty(that.loanProduct.recalculatePenaltyOn);
        that.loanProduct.withContractualSavings = isWithContractualSavings();
      }
    };

    that.loanProduct = {
      minTermAdjustment: 0,
      maxTermAdjustment: 0,
      productDefinition: {
        productAvailability: productAvailability.allBranches.value,
        availableInBranchIds: [],
        allowCreationByAgents: false,
        customerTypeConstraints: [],
        expired: false,
        daysInYear: 360,
        productGroup: 'LOAN',
        employeeProduct: false
      },
      paymentDirection: 'HORIZONTAL',
      paymentIntervalOptions: [],
      pastDueInterestApplyOnMaturity: true,
      pastDueMaturityInterestApplyOn: 'ALL_AMORTIZATIONS',
      defaultPastDueToInterestRate: false,
      defaultPastDueMaturityToInterestRate: false,
      penaltyGracePeriod: 0,
      recalculatePenalty: false,
      calculateDefaultFirstPaymentDate: false,
      validateMinFirstPaymentDate: true,
      interestCalculationMethod: 'SIMPLE',
      amortizationType: 'STRAIGHT',
      maxFirstPaymentPostponement: 0,
      backdatingAllowed: false,
      cycleCounting: false,
      cycles: [],

      principalRoundingScale:              'CENTAVO',
      principalRoundingMode:               'HALF_UP',
      interestRoundingScale:               'CENTAVO',
      interestRoundingMode:                'HALF_UP',
      cbuRoundingScale:                    'CENTAVO',
      cbuRoundingMode:                     'HALF_UP',
      pfRoundingScale:                     'CENTAVO',
      pfRoundingMode:                      'HALF_UP',
      tpRoundingScale:                     'CENTAVO',
      tpRoundingMode:                      'HALF_UP',
      totalAmortizationRoundingScale:      'CENTAVO',
      totalAmortizationRoundingMode:       'HALF_UP',
      docStampRoundingScale:               'CENTAVO',
      docStampRoundingMode:                'HALF_UP',
      notarialFeeRoundingScale:            'CENTAVO',
      notarialFeeRoundingMode:             'HALF_UP',
      applicationFeeRoundingScale:         'CENTAVO',
      applicationFeeRoundingMode:          'HALF_UP',
      creditInvestigationFeeRoundingScale: 'CENTAVO',
      creditInvestigationFeeRoundingMode:  'HALF_UP',
      extraBankFeeRoundingScale:           'CENTAVO',
      extraBankFeeRoundingMode:            'HALF_UP',
      membershipFeeRoundingScale:          'CENTAVO',
      membershipFeeRoundingMode:           'HALF_UP',
      idFeeRoundingScale:                  'CENTAVO',
      idFeeRoundingMode:                   'HALF_UP',
      serviceChargeRoundingScale:          'CENTAVO',
      serviceChargeRoundingMode:           'HALF_UP',
      insuranceFeeRoundingScale:           'CENTAVO',
      insuranceFeeRoundingMode:            'HALF_UP',
      insuranceServiceFeeRoundingScale:    'CENTAVO',
      insuranceServiceFeeRoundingMode:     'HALF_UP',
      insuranceProcessingFeeRoundingScale: 'CENTAVO',
      insuranceProcessingFeeRoundingMode:  'HALF_UP',
      roundingDifferenceTarget:            'PRINCIPAL',

      notarialFee: {rate: 0, type: 'FIXED_AMOUNT'},
      applicationFee: {rate: 0, type: 'FIXED_AMOUNT'},
      creditInvestigationFee: {rate: 0, type: 'FIXED_AMOUNT'},
      serviceCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      extraBankFee: {rate: 0, type: 'FIXED_AMOUNT'},
      membershipFee: {rate: 0, type: 'FIXED_AMOUNT'},
      idFee: {rate: 0, type: 'FIXED_AMOUNT'},
      cbuCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      cbuExtraOptions: {},
      pfCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      pfExtraOptions: {},
      tpCharge: {rate: 0, type: 'FIXED_AMOUNT'},
      tpExtraOptions: {},
      penalty: {rate: 0, type: 'FIXED_AMOUNT'},
      penaltyApplyOnMaturity: 'ALL_AMORTIZATIONS_EXCEPT_LAST',
      penaltyCollectionType: 'PROPORTIONAL_TO_DAYS_DELAYED',
      penaltyMaturity: {rate: 0, type: 'FIXED_AMOUNT', applyOn: 'ALL_AMORTIZATIONS'},
      penaltyMaturityCollectionType: 'PROPORTIONAL_TO_DAYS_DELAYED',
      penaltyApplicationType: 'ALL_AMORTIZATIONS',
      penaltyDaysCalculationType: 'DAYS_LATE',
      insuranceFee: {rate: 0, type: 'FIXED_AMOUNT'},
      insuranceServiceFee: {rate: 0, type: 'FIXED_AMOUNT'},
      insuranceProcessingFee: {rate: 0, type: 'FIXED_AMOUNT'},

      insuranceFeeDeductionStrategy: 'DEDUCT_FROM_RELEASE_AMOUNT',
      insuranceSavingsAccountId: null,  // TODO: add combo box with saving account selections
      duePaymentHierarchy: ['CUSTOM', 'INTEREST', 'PAST_DUE_INTEREST', 'PENALTY', 'CBU', 'PF', 'TP', 'PRINCIPAL'],
      advancePaymentHierarchy: ['CUSTOM', 'PRINCIPAL', 'PAST_DUE_INTEREST', 'PENALTY', 'CBU', 'PF', 'TP', 'INTEREST'],

      manualMetadata: false,
      curePeriod: 0,
      microfinance: false,
      termCalculationMethod: 'EXACT_DAYS',
      calculateMaturityDateByTerm: false,
      performanceThresholdDays: 0,
      feeDefinitions: [],

      asEarnedInterestCalculation: false,
      asEarnedInterestAccrualPeriod: 'DAILY',
      asEarnedPreterminationInterestCalculation: false,
      pretermCollectFullInterest: true,
      collateralRequirement: 'DISALLOWED',

      honorHolidays: false,
      loanHolidays: [],
      depEd: false,
      individualCbuAccountTypeId: null,
      individualPfAccountTypeId: null,
      individualTpAccountTypeId: null,
      corporateCbuAccountTypeId: null,
      corporatePfAccountTypeId: null,
      corporateTpAccountTypeId: null,
      ignoreCSWhenCalculatingLoanStatus: false,
      ignoreCSWhenCalculatingAmortizationStatus: false,
      advancePaymentStrategy: 'PAYMENT_HIERARCHY',
      userDefaultAdvancePaymentStrategy: 'PAYMENT_HIERARCHY',
      cbuAccountTypeId: null,
      allowAccrualOnPastDuePerforming: true,

      penaltyDisplayOnProductCreation: true,
      penaltyMaturityDisplayOnProductCreation: true,

      advanceInterestMaxNo: 0,
      advanceInterestDefaultNo: 0,
      allowRenewal: null,
      renewalMinPaidPrincipalPercentage: null,
      allowReconstruction: null,
      interestType: InterestType.ANNUAL_RATE,
      withLinkedDepositAccount: false,
      letters:[]
    };



    this.cancel = () => {
      const parent = '/admin/loan-products/types';
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parent));
    };

    const convertToCommandInput = () => {
      let commandInput = _.cloneDeep(that.loanProduct);
      commandInput.productGroup = that.loanProduct.productDefinition.productGroup;
      commandInput.productName = that.loanProduct.productDefinition.productName;
      commandInput.productTypeCode = that.loanProduct.productDefinition.productTypeCode;
      commandInput.daysInYear = that.loanProduct.productDefinition.daysInYear;
      commandInput.description = that.loanProduct.productDefinition.description;
      commandInput.productAvailability = that.loanProduct.productDefinition.productAvailability;
      commandInput.availableInBranchIds = that.loanProduct.productDefinition.availableInBranchIds;
      commandInput.allowCreationByAgents = that.loanProduct.productDefinition.allowCreationByAgents;
      commandInput.customerTypeConstraints = that.loanProduct.productDefinition.customerTypeConstraints;
      commandInput.expired = that.loanProduct.productDefinition.expired;
      commandInput.customFieldDefinitionIds = that.loanProduct.productDefinition.customFieldDefinitionIds;
      commandInput.employeeProduct = that.loanProduct.productDefinition.employeeProduct;
      commandInput.perCustomerLimit = that.loanProduct.productDefinition.perCustomerLimit;

      if (that.createMode && !commandInput.penaltyMaturityCollectionType) {
        if (!commandInput.penaltyMaturityCollectionType) {
          commandInput.penaltyMaturityCollectionType = that.loanProduct.penaltyMaturityCollectionType;
        }

        if (!commandInput.penaltyCollectionType) {
          commandInput.penaltyCollectionType = that.loanProduct.penaltyCollectionType;
        }

      }

      if (!commandInput.cycleCounting) {
        commandInput.cycles = null;
      }

      if(commandInput.termCalculationMethod !== 'INTERVAL') {
        commandInput.calculateMaturityDateByTerm = false;
      }

      delete commandInput.productDefinition;
      return commandInput;
    };

    this.confirmChanges = async () => {
      const commandInput = convertToCommandInput();
      if (that.createMode) {
        await command.execute('CreateLoanType', commandInput).toPromise();
        loanProductsCache.refetch();
        depositAccountTypeService.refetch();
        $location.path('/admin/loan-products/types');
      } else {
        commandInput.id = that.loanProduct.id;
        const typeChanges = !(this.generalForm.$pristine && this.interestsForm.$pristine && this.chargesForm.$pristine && this.advancedForm.$pristine && this.holidayForm.$pristine && this.lettersForm.$pristine);
        const loanTypeResponse = !typeChanges && !that.hasChangesInHolidays() ? null : await command.execute('UpdateLoanType', commandInput).toPromise();
        const saveRequest = this.glMappingComponent.save();

        if (loanTypeResponse || saveRequest) {
          loanProductsCache.refetch();
          notification.show("Success", "Loan Product updated successfully");
          $location.path('/admin/loan-products/types');
          that.clearCache();
        } else {
          notification.show("Information", "No changes have been made");
          $location.path('/admin/loan-products/types');
        }
      }
    };

    that.hasChangesInHolidays = () => {
      return that.loanProduct.holidaysCount !== that.loanProduct.loanHolidays.length;
    };

    that.holidayFormsValid = () => {
      const notSavedHolidays = that.loanProduct.loanHolidays.filter(holiday => holiday.dirty === true);
      return notSavedHolidays.length < 1;
    };

    that.letterFormsValid = () => {
      const unsavedLetters = that.loanProduct.letters.filter(letter => letter.inputMode === true);
      return unsavedLetters.length < 1;
    }

    this.clearCache = () => {
      loanProductsCache.refetch();
      ledgerTagCache.evict();
      feeDefinitionsCache.evict();
      productDefinitionService.evict();
      permissionService.evict();
    };

    this.setGlMappingComponent = (instance) => {
      this.glMappingComponent = instance;
    };

    const isWithContractualSavings = () => {
      return that.loanProduct.individualCbuAccountTypeId != null ||
        that.loanProduct.corporateCbuAccountTypeId != null ||
        that.loanProduct.individualPfAccountTypeId != null ||
        that.loanProduct.corporatePfAccountTypeId != null ||
        that.loanProduct.individualTpAccountTypeId != null ||
        that.loanProduct.corporateTpAccountTypeId != null;
    };

    that.isAccrualAllowed = () => {
      return !that.loanProduct.allowAccrual;
    }

  }
});
