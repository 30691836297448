import _ from "lodash";

import nxModule from 'nxModule';
import systemPropertyService from 'system/systemPropertyService';
import './report-view.style.less';

const templateUrl = require('./report-view.template.html');

const commonReportDescriptionToKeepInOrder = [
  "Organization",
  "Branch",
  "TIN",
  "Report name",
  "Generated on",
  "Generated by",
  "Checked by",
  "Approved by",
  'Start date',
  'End date',
  'Date'
];

const keepOnlyKeys = (obj, keys) => {
  Object.keys(obj).forEach(key => {
    if (!keys.includes(key)) {
      delete obj[key];
    }
  });
}

const orderHeadersAndAddIfNotAvailable = (headers) => {
  const orderedHeaders = {};

  // Add the specific headers to the orderedHeaders object, ensuring blank values if missing
  commonReportDescriptionToKeepInOrder.forEach(header => {
    orderedHeaders[header] = headers[header] || "";
  });

  // Add the remaining headers that are not in the specific order
  for (const key in headers) {
    if (!commonReportDescriptionToKeepInOrder.includes(key)) {
      orderedHeaders[key] = headers[key];
    }
  }

  return orderedHeaders;
}


nxModule.component('reportView', {
  templateUrl,
  bindings: {
    reportContent: '<',
    container: '<',
    lazyDisplay: '<', // if not present, default value is true
    descriptor: '<'
  },
  controller: function ($scope) {
    const that = this;
    that.container = that.container || null;
    that.lazyDisplay = that.lazyDisplay ?? true;
    that.truncateLongReportValue = systemPropertyService.getProperty('REPORT_VIEW_TRUNCATE_LONG_VALUE') === 'TRUE';

    $scope.$watch('$ctrl.reportContent', () => {
      if (!that.reportContent) return;

      that.reportGenerated = true;
      that.reportColumnsNum = findColumnNumber(that.reportContent);
      that.hasData = checkIfHasData(that.reportContent);

      if(that.descriptor && that.descriptor.outputTypes && that.descriptor.outputTypes.includes('XLS','CSV')) {
        keepOnlyKeys(that.reportContent[0].reportDescription, commonReportDescriptionToKeepInOrder);
        that.reportContent[0].reportDescription = orderHeadersAndAddIfNotAvailable(that.reportContent[0].reportDescription);
      }
    
      that.reportContent.forEach(sheet => sheet.limit = 100);
    });

    that.increaseLimit = () => {
      if(!that.reportContent || !that.lazyDisplay) {
        return;
      }

      const notFullyDisplayedSheet = that.reportContent.find(sheet => sheet.limit < sheet.rows.length);
      if(notFullyDisplayedSheet) {
        notFullyDisplayedSheet.limit += 100;
      }
    };

    function checkIfHasData (reports) {
      return _(reports).map((r) => r.rows).flatten().value().length > 0
    }

    function findColumnNumber (reports) {
      return _(reports).map((report) => {
        return _(report.rows).map((a) => a.cells.length + report.xoffset).max();
      }).max();
    }

  }
});
